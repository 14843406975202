import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import React from 'react';
import { IFilteringViewModel } from '../../_common/interface';
import { setTableSort } from '../../_helpers/table-helper';

export function CommonTableHead(params: { componentProps: any, columns: Array<any>, filter: IFilteringViewModel, anyActions?: boolean, setFilter: (obj: IFilteringViewModel) => void }) {

    return (
        <TableHead>
            <TableRow>

                {params.anyActions === true && <TableCell key={"_key"}>
                    <TableSortLabel>
                    </TableSortLabel>
                </TableCell>}

                {params.columns && params.columns.map(h =>
                    <TableCell key={h.title}
                        sortDirection={params.filter.orderBy === h.field ? params.filter.order : false}>

                        {h.header && <h.header></h.header>}
                        
                        {!h.header &&
                            <Tooltip
                                title="Sort"
                                enterDelay={300}>
                                <TableSortLabel
                                    hideSortIcon={params.filter.orderBy !== h.field}
                                    active={params.filter.orderBy === h.field}
                                    direction={params.filter.order}
                                    onClick={e => setTableSort(params.filter, h.field, params.setFilter)}>
                                    {h.title}
                                </TableSortLabel>

                            </Tooltip>
                        }
                    </TableCell>
                )}
            </TableRow>

        </TableHead>
    );
}