//import { accountService } from '../_services';
import { accountService } from '../_services/account.service';

export function handleResponse(response: Response) {
    return response.text().then(text => {
        let data;

        if (text.length && text.length > 1000)
            console.log("jsonsize", text.length);

        if (text.length && text.length > 10000)
            console.error("ERROR jsonsize", text.length);

        try {
            data = text && JSON.parse(text);
        } catch (e) {
            data = text;
        }
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                accountService.logout();
                //window.location.reload(true);
            }

            return Promise.reject(data);
        }
        return data;
    });
}

export function handleFailResponse(response: any) {
    console.log("fail", response);
    //accountService.logout();
}