import { Icon, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useDrawerStyles } from '../../_styles';

const useStyles = makeStyles(theme => ({
    section: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        height: "60px",
        color: "white"
    },
    header: {
        //color: RGB_BACKGROUND_TWO,
        textTransform: "uppercase",
        padding: "20px",
        marginBottom: "20px",
        fontWeight: "bold",
        fontSize: "20px",
        width: "90%",
        float: "left"
    }
}));

export function SectionHeadingDrawer(props: { heading: string, emitOnClose?: () => void }) {

    const classes = useStyles();
    const drawerClasses = useDrawerStyles();

    return (
        <div className={classes.section}>

            <div className={classes.header}>{props.heading}</div>

            {props.emitOnClose &&
                <IconButton aria-label="close" className={drawerClasses.closeButton} onClick={props.emitOnClose}>
                    <Icon>close</Icon>
                </IconButton>
            }
        </div>
    );
}