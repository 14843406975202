
export const RESET_PASSWORD_ALPHA_NUMERIC = "@\$!%\*\#\?\+=\.,;:&%\^-_~\|\(\)£<>\{\}";
export const RESET_PASSWORD_REGEX = "^(?=.*[A-Z])(?=.*\\d)(?=.*[" + RESET_PASSWORD_ALPHA_NUMERIC + "])[A-Za-z\\d" + RESET_PASSWORD_ALPHA_NUMERIC + "]{6,100}$";

export const RESET_PASSWORD_VALIDATORS = (isRequired?: boolean) => {
    let validation = [

        `matchRegexp:[A-Z]`,
        `matchRegexp:[a-z]`,
        `matchRegexp:\\d`,
        `matchRegexp:[${RESET_PASSWORD_ALPHA_NUMERIC}]`
    ];

    if (isRequired) {
        validation = [`minStringLength:6`, ...validation];
    }

    return validation;
};

export const RESET_PASSWORD_ERROR_MESSAGES = (isRequired?: boolean) => {
    let validation = [
        "At least one uppercase A-Z must be present",
        "At least one lowercase a-z must be present",
        "At least one number must be present",
        `At least one non alpha numeric [${RESET_PASSWORD_ALPHA_NUMERIC}] must be included`
    ];

    if (isRequired) {
        validation = [
            `Password must be at least 6 chars, contain at least one uppercase and lowercase A-Z, at least one non alpha numeric (${RESET_PASSWORD_ALPHA_NUMERIC}) and at least one number.`,
            ...validation];
    }

    return validation;
};

export const RESET_PASSWORD_HELPER_TEXT = `Password must be at least 6 chars, contain at least one uppercase and lowercase A-Z, at least one non alpha numeric (${RESET_PASSWORD_ALPHA_NUMERIC}) and at least one number.`;

