import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { PRIMARY_COLOR } from '../../_common/const';
import Icon from '@material-ui/core/Icon';
import { Grid, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    icon: {
        fontSize: 32,
        margin: theme.spacing(2),
    },
    iconPaper: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(-3),
        width: theme.spacing(8),
        height: theme.spacing(8),
        backgroundColor: `${PRIMARY_COLOR[0]} !important`,
        color: 'white !important',
    },
    heading: {
        margin: theme.spacing(1),
        color: theme.palette.grey[600],
        alignItems: 'left',
        textAlign: 'left'
    },
    buttons: {
        "&>*": {
            float: "right"
        }
    }
}),
);

export function PaperHeader(props: { title: String, icon?: String, children?: React.ReactNode }) {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12} md={props.children ? 4 : 12} sm={props.children ? 5 : 12}>
                <div className={classes.root}>

                    <Paper elevation={3} className={classes.iconPaper}>
                        <Icon className={classes.icon}>{props.icon}</Icon>
                    </Paper>

                    <Typography variant="h5" component="h1" className={classes.heading}>
                        {props.title}
                    </Typography>
                </div>
            </Grid>

            <Grid item xs={12} md={8} sm={7} className={classes.buttons}>
                {props.children && props.children}
            </Grid>

        </Grid>
    );
}