import moment from 'moment';
export const getDateString = (date: Date) => {
    date = new Date(date);
    let yy = date.getFullYear();
    let mm = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return `${yy}-${mm}-${dd}`;
}

export const getStringDate = (date: string) => {
    var _date = new Date(date);
    return getDateString(_date);
}

export const getDateStringNormalized = (date: Date, withTime?: boolean) => {
    if (!date) return;

    if (date instanceof Date !== true) {
        date = new Date(date);
    }

    let yy = date.getFullYear();
    let mm = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    var _return = `${dd}/${mm}/${yy}`;

    if (withTime) {
        let hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let MM = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return `${_return} ${hh}:${MM}`;
    }
    return _return;
}

export const getStringDateNormalized = (dateString?: string, withTime?: boolean) => {
    if (!dateString) return "";
    let date = new Date(dateString);
    return getDateStringNormalized(date, withTime);
}

export const areDatesSame = (date1: Date, date2: Date) => {
    date1 = new Date(date1);
    date2 = new Date(date2);

    date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return date1.toString() == date2.toString();
}

export const isDateWithin = (date: Date, startDate: Date, endDate: Date) => {
    date = new Date(date);
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    return date >= startDate && date <= endDate;
}

export const doDatesOverlap = (_startDate: Date, _endDate: Date, startDate: Date, endDate: Date) => {
    return (isDateWithin(_startDate, startDate, endDate) || isDateWithin(_endDate, startDate, endDate) || (new Date(_startDate) < startDate && new Date(_endDate) > endDate))
}


export const isDateWeekend = (date: Date) => {
    date = new Date(date);
    return date.getDay() === 6 || date.getDay() === 0;
}


export const getDateWithAddedDays = (date: Date, days: number) => {
    return new Date(new Date(date).setDate(new Date(date).getDate() + days))
}


export const getDaysDifferenceBetweenDates = (startDate: Date, endDate: Date) => {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    var difference = endDate.getTime() - startDate.getTime();
    var days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
}

export const getMondayNextWeekString = () => {
    const mondayNextWeekString = getDateString(getMondayNextWeek());
    return mondayNextWeekString;
}

export const getMondayNextWeek = () => {
    return new Date(moment().add(1, "weeks").startOf('isoWeek').format("yyyy-MM-DD"));
}

export const getSundayNextWeek = () => {
    return new Date(moment().add(1, "weeks").endOf('isoWeek').format("yyyy-MM-DD"));
}

export const isStringDateWithin = (date: Date, startDate: string, endDate: string) => isDateWithin(date, new Date(startDate), new Date(endDate));

export const getWeek = (date: Date) => {
    /*
    date.setHours(0, 0, 0, 0);
    var _dayMl = 86400000;
    // Thursday in current week decides the year.
    var _first = new Date("Jan 01, " + date.getFullYear() + " 01:00:00");
    var _second = new Date(_first.getTime() + ((7 - _first.getDay()) * _dayMl));

    return ((Math.floor((date.getTime() - _second.getTime()) / (86400000 * 7))) + 1) + 1;
    */

    return parseInt(moment(date).format("W"));
}

export const isPrevWeek = (week: number, year: number) => {
    return week < getWeek(new Date) && year <= (new Date()).getFullYear();
}

export const isCurrentOrPrevWeek = (week: number, year: number) => {
    return week <= getWeek(new Date) && year <= (new Date()).getFullYear();
}

export const getOffsetFromCurrentWeek = (date: Date) => {
    if (date.getFullYear() != (new Date()).getFullYear()) return "-";
    let offset = getWeek(date) - getWeek(new Date());
    return offset > 0 ? "+" + offset : offset;
}

export const isCurrentWeek = (week: number, year: number) => {
    return week === getWeek(new Date) && year == (new Date()).getFullYear();
}

export const isFutureWeek = (week: number, year: number) => {
    return week > getWeek(new Date) && year >= (new Date()).getFullYear();
}

export const isCurrentOrFutureWeek = (week: number, year: number) => {
    return week >= getWeek(new Date) && year >= (new Date()).getFullYear();
}

export const getDateStringFromWeek = (week: number, year: number) => {
    /*
    week = week - 1;
    var year = year;
    var _dayMl = 86400000;
    week = week;
    var d = new Date("Jan 01, " + year + " 01:00:00");
    var w = d.getTime();

    if (week > 0)
        w = w + ((_dayMl * 7 * week) - (_dayMl * (d.getDay() - 1)));

    var n1 = new Date(w);
    var n2 = new Date(w + (_dayMl * (7 - n1.getDay())));

    if (n2.getFullYear() != year) {
        n2 = new Date(year + "-12=31");
    }
*/

    return moment([year]).week((week + 1)).startOf('isoWeek').format("DD-MM-yyyy") + " - " + moment([year]).week((week + 1)).endOf('isoWeek').format("DD-MM-yyyy");
}
