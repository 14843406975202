import { createTheme } from '@material-ui/core';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '.';

export const THEME = createTheme({
    palette: {
        primary: {
            main: PRIMARY_COLOR[0],
        },
        secondary: {
            main: SECONDARY_COLOR[0]
        }
    }
});